<template>
  <v-container
    id="user-profile"
    fluid
    tag="section"
    class="px-0 mx-0"
  >
<!-- {{ fetchedOrLiveUser }} -->
<!--   {{ personalData }} -->

  <v-row
  >
    <v-col
      cols="12"
      class="mx-1 px-2"
      v-if="invalidTaxNumberNotification"
    >
      <base-material-alert
              color="red"
              dark
              icon="mdi-bell"
              class="invalid-vat-notification"
            >
              Your corporate EU Tax Number is invalid. Therefore we can not provide you with VAT-free purchases.
      </base-material-alert>
    </v-col>

    <v-col
      cols="12"
      class="mx-1 px-2"
      v-if="validTaxNumberNotification"
    >
      <base-material-alert
              color="green"
              dark
              dismissible
              icon="mdi-bell"
              class="invalid-vat-notification"
            >
              Thank you. Your EU Tax Number is valid. We are offering VAT-free prices for your corporation.
      </base-material-alert>
    </v-col>

    <v-col
      cols="12"
      class="mx-1 px-2"
      v-if="validPersonalNotification"
    >
      <base-material-alert
              color="green"
              dark
              dismissible
              icon="mdi-bell"
              class="invalid-vat-notification"
            >
              Thank you. Your personal account is saved.
      </base-material-alert>
    </v-col>

    <v-col
      cols="12"
      class="mx-1 px-2"
      v-if="errorOccured"
    >
      <base-material-alert
              color="green"
              dark
              dismissible
              icon="mdi-bell"
              class="invalid-vat-notification"
            >
              We have a server error, we are already working on it. Please try later.
      </base-material-alert>
    </v-col>

  </v-row>
    <v-row 
      justify="center"
      class="mx-0 px-0"
      >
      <v-col
        cols="12"
        md="8"
        class="mx-0 px-0"
      >
          <base-material-card class="px-0 mx-0 py-3" color="primary">
          <template v-slot:heading class="primary">
            <v-tabs
              v-model="tabs"
              background-color="primary"
              slider-color="white"
            >
              <v-icon v-if="windowWidth > 350" class="mr-2">
                  mdi-account-outline
              </v-icon>
              <v-tab class="mr-3">
                <v-icon v-if="windowWidth > 500" class="mr-2">
                  mdi-pencil
                </v-icon>
                {{mainTitle1}}
              </v-tab>
<!--               <v-tab class="mr-3">
                <v-icon v-if="windowWidth > 500" class="mr-2">
                  mdi-pencil
                </v-icon>
                {{mainTitle2}}
              </v-tab> -->

            </v-tabs>
          </template>

          <v-tabs-items
            v-model="tabs"
            class="transparent"
            touchless
          >
            <v-tab-item
            >
              <v-card-text>

                  <form>
                    <v-row
                      style="max-width: 500px;"
                      class="my-10"
                    >
                      <v-col
                        cols="auto"
                        class="text-center"
                      >
                        <input
                          ref="file"
                          type="file"
                          class="d-none"
                          @change="onChange"
                        >
                        <v-card
                          :class="image ? 'primary--text' : 'grey--text'"
                          class="mx-auto mt-0 d-inline-flex v-card--account"
                          outlined
                          style="border: 4px solid rgba(0, 0, 0, 0.5)!important;"
                          height="106"
                          width="106"
                          @click="$refs.file.click()"
                        >
                          <v-img
                            v-if="image"
                            :src="image"
                            height="100%"
                            width="100%"
                          />
                          <v-icon
                            v-else
                            class="mx-auto"
                            size="96"
                          >
                            mdi-account
                          </v-icon>
                        </v-card>

                        <div class="font-weight-bold grey--text">
                          CHOOSE PICTURE
                        </div>
                      </v-col>

                      <v-col
                        md="8"
                        align-self="center"
                      >
                        <base-subheading
                          subheading="Please select your profile picture"
                          />
                      </v-col>
                    </v-row>
                    </form>
                        <base-material-tabs
                          color="primary"
                          icons-and-text
                          class="my-5"
                          centered
                          v-model="tabIndex"
                        >
                          <v-tab
                            v-for="(tab, i) in profileTypes"
                            :key="i"
                            class="mb-5"
                          >
                            {{ tab.text }}
                            <v-icon v-text="tab.icon" />
                          </v-tab>

                          <v-tab-item>
                            <v-card
                              flat
                              class="my-0 pt-10"
                            >
                              <v-card-text class="pt-0">
                                <validation-observer v-slot="{ handleSubmit }">
                                  <form @submit.prevent="handleSubmit(validateForm)">
                                    <v-container class="py-0">
                                      <v-row>

                                        <v-col
                                          cols="12"
                                          md="4"
                                        >
                                          <v-text-field
                                            label="Email user_address"
                                            class="purple-input"
                                            disabled
                                            :value="fetchedOrLiveUser.email"
                                          />
                                        </v-col>

                                        <v-col
                                          cols="12"
                                          md="6"
                                        >
                                          <validation-provider
                                              v-slot="{ errors }"
                                              name="First name"
                                              rules="required"
                                          >
                                            <v-text-field
                                              label="First Name"
                                              class="purple-input"
                                              v-model="personalData.first_name"
                                              :error-messages="errors"
                                            />
                                          </validation-provider>
                                        </v-col>

                                        <v-col
                                          cols="12"
                                          md="6"
                                        >
                                          <validation-provider
                                              v-slot="{ errors }"
                                              name="Last name"
                                              rules="required"
                                          >                              
                                            <v-text-field
                                              label="Last Name"
                                              class="purple-input"
                                              v-model="personalData.last_name"
                                              :error-messages="errors"
                                            />
                                          </validation-provider>
                                        </v-col>

                                        <v-col cols="12">
                                          <validation-provider
                                              v-slot="{ errors }"
                                              name="Street name"
                                              rules="required"
                                          >
                                            <v-text-field
                                              label="Street / square / etc name"
                                              class="purple-input"
                                              v-model="personalData.user_address.street"
                                              :error-messages="errors"
                                            />
                                          </validation-provider>
                                        </v-col>

                                        <v-col cols="12">
                                          <validation-provider
                                              v-slot="{ errors }"
                                              name="House number"
                                              rules="required"
                                          >                                
                                            <v-text-field
                                              label="House number"
                                              class="purple-input"
                                              v-model="personalData.user_address.housenumber"
                                              :error-messages="errors"
                                            />
                                          </validation-provider>
                                        </v-col>

                                        <v-col
                                          cols="12"
                                          md="4"
                                        >
                                          <validation-provider
                                              v-slot="{ errors }"
                                              name="City"
                                              rules="required"
                                          >                              
                                            <v-text-field
                                              label="City"
                                              class="purple-input"
                                              v-model="personalData.user_address.city"
                                              :error-messages="errors"
                                            />
                                          </validation-provider>
                                        </v-col>

                                        <v-col
                                          cols="12"
                                          md="4"
                                        >
                                          <validation-provider
                                              v-slot="{ errors }"
                                              name="Country"
                                              rules="required"
                                          >                                        
                                            <v-select
                                              :items="countries"
                                              v-model="personalData.user_address.country"
                                              :value="personalData.user_address.country"
                                              label="Select country"
                                              outlined
                                              :error-messages="errors"
                                            ></v-select>
                                          </validation-provider>
                                        </v-col>

                                        <v-col
                                          cols="12"
                                          md="4"
                                        >
                                          <validation-provider
                                              v-slot="{ errors }"
                                              name="Postal code"
                                              rules="required"
                                          >                              
                                            <v-text-field
                                              class="purple-input"
                                              label="Postal Code"
                                              type="number"
                                              v-model="personalData.user_address.ZIP"
                                              :error-messages="errors"
                                            />
                                          </validation-provider>
                                        </v-col>

                                        <v-col
                                          cols="12"
                                          class="text-right"
                                        >
                                          <v-btn
                                            color="primary"
                                            class="mr-0"
                                            type="submit"
                                            default
                                            :disabled="isButtonBusy"
                                          >
                                            Update Profile
                                            <template
                                            v-if="isButtonBusy"
                                            >
                                              <v-progress-circular
                                                indeterminate
                                                color="red"
                                                style="ml-3"
                                              ></v-progress-circular>
                                            </template>
                                          </v-btn>
                                        </v-col>
                                      </v-row>
                                    </v-container>
                                  </form>
                                </validation-observer>
                              </v-card-text>

                            </v-card>
                          </v-tab-item>

                          <v-tab-item>
                            <v-card
                              flat
                              class="my-0 pt-10"
                            >
                              <v-card-text class="pt-0">
                                <validation-observer v-slot="{ handleSubmit }">
                                  <form @submit.prevent="handleSubmit(validateForm)">
                                    <v-container class="py-0">
                                      <v-row>

                                        <v-col
                                          cols="12"
                                          md="4"
                                        >
                                          <v-text-field
                                            label="Email user_address"
                                            class="purple-input"
                                            disabled
                                            :value="fetchedOrLiveUser.email"
                                          />
                                        </v-col>

                                        <v-col
                                          cols="12"
                                          md="6"
                                        >
                                          <validation-provider
                                              v-slot="{ errors }"
                                              name="First name"
                                              rules="required"
                                          >
                                            <v-text-field
                                              label="First Name"
                                              class="purple-input"
                                              v-model="personalData.first_name"
                                              :error-messages="errors"
                                            />
                                          </validation-provider>
                                        </v-col>

                                        <v-col
                                          cols="12"
                                          md="6"
                                        >
                                          <validation-provider
                                              v-slot="{ errors }"
                                              name="Last name"
                                              rules="required"
                                          >                              
                                            <v-text-field
                                              label="Last Name"
                                              class="purple-input"
                                              v-model="personalData.last_name"
                                              :error-messages="errors"
                                            />
                                          </validation-provider>
                                        </v-col>

                                        <v-col cols="12">
                                          <validation-provider
                                              v-slot="{ errors }"
                                              name="Street name"
                                              rules="required"
                                          >
                                            <v-text-field
                                              label="Street / square / etc name"
                                              class="purple-input"
                                              v-model="personalData.user_address.street"
                                              :error-messages="errors"
                                            />
                                          </validation-provider>
                                        </v-col>

                                        <v-col cols="12">
                                          <validation-provider
                                              v-slot="{ errors }"
                                              name="House number"
                                              rules="required"
                                          >                                
                                            <v-text-field
                                              label="House number"
                                              class="purple-input"
                                              v-model="personalData.user_address.housenumber"
                                              :error-messages="errors"
                                            />
                                          </validation-provider>
                                        </v-col>

                                        <v-col
                                          cols="12"
                                          md="4"
                                        >
                                          <validation-provider
                                              v-slot="{ errors }"
                                              name="City"
                                              rules="required"
                                          >                              
                                            <v-text-field
                                              label="City"
                                              class="purple-input"
                                              v-model="personalData.user_address.city"
                                              :error-messages="errors"
                                            />
                                          </validation-provider>
                                        </v-col>

<!--                                         <v-col
                                          cols="12"
                                          md="4"
                                        >
                                          <validation-provider
                                              v-slot="{ errors }"
                                              name="Country"
                                              rules="required"
                                          >                              
                                            <v-text-field
                                              label="Country"
                                              class="purple-input"
                                              
                                              :error-messages="errors"
                                            />
                                          </validation-provider>
                                        </v-col> -->

                                        <v-col
                                          cols="12"
                                          md="4"
                                        >
                                          <validation-provider
                                              v-slot="{ errors }"
                                              name="Country"
                                              rules="required"
                                          >                                        
                                            <v-select
                                              :items="countries"
                                              v-model="personalData.user_address.country"
                                              :value="personalData.user_address.country"
                                              label="Select country"
                                              outlined
                                              :error-messages="errors"
                                            ></v-select>
                                          </validation-provider>
                                        </v-col>

                                        <v-col
                                          cols="12"
                                          md="4"
                                        >
                                          <validation-provider
                                              v-slot="{ errors }"
                                              name="Postal code"
                                              rules="required"
                                          >                              
                                            <v-text-field
                                              class="purple-input"
                                              label="Postal Code"
                                              type="number"
                                              v-model="personalData.user_address.ZIP"
                                              :error-messages="errors"
                                            />
                                          </validation-provider>
                                        </v-col>
                                
                                        <v-col
                                          cols="12"
                                        >
                                          <v-checkbox
                                            label="Company address and personal address is the same"
                                            class="mt-0"
                                            v-model="copierSwitch"
                                            @change="sameData($event)"
                                          />
                                        </v-col>

                                        <v-col
                                          cols="12"
                                          md="6"
                                        >
                                          <validation-provider
                                              v-slot="{ errors }"
                                              name="Company name"
                                              rules="required"
                                          >                              
                                            <v-text-field
                                              label="Company name"
                                              class="purple-input"
                                              v-model="personalData.company_name"
                                              :disabled="copierSwitch"
                                              :error-messages="errors"
                                            />
                                          </validation-provider>
                                        </v-col>

                                        <v-col
                                          cols="12"
                                          md="6"
                                        >
                                          <validation-provider
                                              v-slot="{ errors }"
                                              name="EU-VAT-number"
                                              rules="required"
                                          >                              
                                            <v-text-field
                                              label="EU VAT number"
                                              class="purple-input"
                                              v-model="personalData.tax_number"
                                              :disabled="copierSwitch"
                                              :error-messages="errors"
                                            />
                                          </validation-provider>
                                        </v-col>

                                        <v-col cols="12">
                                          <validation-provider
                                              v-slot="{ errors }"
                                              name="Company seat - street"
                                              rules="required"
                                          >                                
                                            <v-text-field
                                              label="Company seat - street / square / etc name"
                                              class="purple-input"
                                              v-model="personalData.company_seat.street"
                                              :disabled="copierSwitch"
                                              :error-messages="errors"
                                            />
                                          </validation-provider>
                                        </v-col>

                                        <v-col cols="12">
                                          <validation-provider
                                              v-slot="{ errors }"
                                              name="Company seat - house number"
                                              rules="required"
                                          >                                
                                            <v-text-field
                                              label="Company seat - house number"
                                              class="purple-input"
                                              v-model="personalData.company_seat.housenumber"
                                              :disabled="copierSwitch"
                                              :error-messages="errors"
                                            />
                                          </validation-provider>
                                        </v-col>

                                        <v-col
                                          cols="12"
                                          md="4"
                                        >
                                          <validation-provider
                                              v-slot="{ errors }"
                                              name="Company seat - city"
                                              rules="required"
                                          >                              
                                            <v-text-field
                                              label="Company seat - City"
                                              class="purple-input"
                                              v-model="personalData.company_seat.city"
                                              :disabled="copierSwitch"
                                              :error-messages="errors"
                                            />
                                          </validation-provider>
                                        </v-col>

                                        <v-col
                                          cols="12"
                                          md="4"
                                        >
                                          <validation-provider
                                              v-slot="{ errors }"
                                              name="Country"
                                              rules="required"
                                          >                                        
                                            <v-select
                                              :items="countries"
                                              v-model="personalData.company_seat.country"
                                              :value="personalData.company_seat.country"
                                              label="Select country"
                                              outlined
                                              :error-messages="errors"
                                            ></v-select>
                                          </validation-provider>
                                        </v-col>

                                        <v-col
                                          cols="12"
                                          md="4"
                                        >
                                          <validation-provider
                                              v-slot="{ errors }"
                                              name="Company seat - Postal code"
                                              rules="required"
                                          >                              
                                            <v-text-field
                                              class="purple-input"
                                              label="Company seat - Postal Code"
                                              v-model="personalData.company_seat.ZIP"
                                              :disabled="copierSwitch"
                                              :error-messages="errors"
                                            />
                                          </validation-provider>
                                        </v-col>

                                        <v-col
                                          cols="12"
                                          class="text-right"
                                        >
                                          <v-btn
                                            color="primary"
                                            class="mr-0"
                                            type="submit"
                                            :disabled="isButtonBusy"
                                            default
                                          >
                                            Update Profile
                                          <template
                                            v-if="isButtonBusy"
                                            >
                                              <v-progress-circular
                                                indeterminate
                                                color="red"
                                                style="ml-3"
                                              ></v-progress-circular>
                                            </template>
                                          </v-btn>
                                        </v-col>
                                      </v-row>
                                    </v-container>
                                  </form>
                                </validation-observer>
                              </v-card-text>

                            </v-card>
                          </v-tab-item>
                        </base-material-tabs>
                
              </v-card-text>
            </v-tab-item>

            <v-tab-item
            >
              <v-card-text
                class="px-0 mx-0"
              >
                        <div class="wrapper" id="app">
                          <card-form
                            :form-data="cardData"
                            @input-card-number="updateCardNumber"
                            @input-card-name="updateCardName"
                            @input-card-month="updateCardMonth"
                            @input-card-year="updateCardYear"
                            @input-card-cvv="updateCardCvv"
                          />
                        </div>
              </v-card-text>
            </v-tab-item>

          </v-tabs-items>
        </base-material-card>


      </v-col>


    </v-row>
  </v-container>
</template>

<script>
  import {
    mapGetters
  } from 'vuex'
  import CardForm from '@/components/CreditCardForm'

  export default {
    name: 'UserProfile',
    components: {
      CardForm
    },

    props: ['fetchedOrLiveUser'],

    data: () => ({
          countries : [
            { text: 'Hungary', value: 'HU' },
            { text: 'Austria', value: 'AT' },
            { text: 'Slovakia', value: 'SK' },
          ],
          /* countries : ['Hungary', 'Austria', "Slovakia"], */
          validPersonalNotification: false,
          errorOccured: false,
          isButtonBusy : false,
          invalidTaxNumberNotification : false,
          validTaxNumberNotification : false,
          copierSwitch : false,
          tabIndex: 0,
          mainTitle1: '',
          mainTitle2: '',
          windowWidth : null,
          tabs: 0,
          image: null,
          cardData: {
            cardName: '',
            cardNumber: '',
            cardMonth: '',
            cardYear: '',
            cardCvv: ''
          },
          personalData: {
            userID: null,
            is_company: false,
            first_name: '',
            last_name: '',
            company_name: '',
            tax_number: '',
            user_address: {
              country: '',
              city: '',
              street: '',
              housenumber: '',
              ZIP: '',
            },
            company_seat: {
              country: '',
              city: '',
              street: '',
              housenumber: '',
              ZIP: '',
            }
          },
          profileTypes: [
        {
          text: 'Personal',
          icon: 'mdi-account-tie',
        },
        {
          text: 'Company',
          icon: 'mdi-bank',
        },
      ],
    }),
    
    mounted () {
      this.$i18n.locale = 'en'
      if (window.innerWidth < 501) {
        this.mainTitle1 = 'Personal'
        this.mainTitle2 = 'Financial'
      } else {
        this.mainTitle1 = 'Personal Data'
        this.mainTitle2 = 'Financial Data'
      }
      window.addEventListener('resize', () => {
        this.windowWidth = window.innerWidth
        if (this.windowWidth < 501) {
          this.mainTitle1 = 'Personal'
          this.mainTitle2 = 'Financial'
        } else {
          this.mainTitle1 = 'Personal Data'
          this.mainTitle2 = 'Financial Data'
        }
      })
    },
    created() {
      this.fillData();
    },
    computed: {
      ...mapGetters(["currentUser"]),
    },
    methods: {
      fillData() {
        this.personalData.is_company = this.fetchedOrLiveUser.is_company;
        if (this.personalData.is_company) {
          this.tabIndex = 1
        } else {
          this.tabIndex = 0
        }

        this.personalData.userID = this.fetchedOrLiveUser._id;

        if (this.fetchedOrLiveUser.first_name) {
          this.personalData.first_name = this.fetchedOrLiveUser.first_name;
        }
        if (this.fetchedOrLiveUser.last_name) {
          this.personalData.last_name = this.fetchedOrLiveUser.last_name;
        }
        if (this.fetchedOrLiveUser.user_address) {
          if (this.fetchedOrLiveUser.user_address.country) {
            this.personalData.user_address.country = this.fetchedOrLiveUser.user_address.country;
          }
          if (this.fetchedOrLiveUser.user_address.city) {
            this.personalData.user_address.city = this.fetchedOrLiveUser.user_address.city;
          }
          if (this.fetchedOrLiveUser.user_address.street) {
            this.personalData.user_address.street = this.fetchedOrLiveUser.user_address.street;
          }
          if (this.fetchedOrLiveUser.user_address.housenumber) {
            this.personalData.user_address.housenumber = this.fetchedOrLiveUser.user_address.housenumber;
          }
          if (this.fetchedOrLiveUser.user_address.ZIP) {
            this.personalData.user_address.ZIP = this.fetchedOrLiveUser.user_address.ZIP;
          }
        }

        if (this.fetchedOrLiveUser.company_name) {
          this.personalData.company_name = this.fetchedOrLiveUser.company_name;
        }
        if (this.fetchedOrLiveUser.tax_number || this.fetchedOrLiveUser.tax_number === "") {
          this.personalData.tax_number = this.fetchedOrLiveUser.tax_number;
        }

        if (this.fetchedOrLiveUser.is_company) {
          if (this.fetchedOrLiveUser.company_seat) {
            if (this.fetchedOrLiveUser.company_seat.country) {
              this.personalData.company_seat.country = this.fetchedOrLiveUser.company_seat.country;
            }
            if (this.fetchedOrLiveUser.company_seat.city) {
              this.personalData.company_seat.city = this.fetchedOrLiveUser.company_seat.city;
            }
            if (this.fetchedOrLiveUser.company_seat.street) {
              this.personalData.company_seat.street = this.fetchedOrLiveUser.company_seat.street;
            }
            if (this.fetchedOrLiveUser.company_seat.housenumber) {
              this.personalData.company_seat.housenumber = this.fetchedOrLiveUser.company_seat.housenumber;
            }
            if (this.fetchedOrLiveUser.company_seat.ZIP) {
              this.personalData.company_seat.ZIP = this.fetchedOrLiveUser.company_seat.ZIP;
            }
          }
        }

},
      updateCardNumber (val) {
      },
      updateCardName (val) {
      },
      updateCardMonth (val) {
      },
      updateCardYear (val) {
      },
      updateCardCvv (val) {
      },
      onChange (val) {
        const value = val.target.files[0]
        if (!value) return (this.image = null)
        this.image = URL.createObjectURL(value)
      },
      sameData(event) {
        if (event) {
          this.personalData.company_name = `${this.personalData.first_name} ${this.personalData.last_name}`
          this.personalData.company_seat.country = `${this.personalData.user_address.country}`
          this.personalData.company_seat.city = `${this.personalData.user_address.city}`
          this.personalData.company_seat.street = `${this.personalData.user_address.street}`
          this.personalData.company_seat.housenumber = `${this.personalData.user_address.housenumber}`
          this.personalData.company_seat.ZIP = `${this.personalData.user_address.ZIP}`
        }
      },
      async validateForm (scope) {
        this.isButtonBusy = true
        let newIsCompany = false
        if (this.tabIndex === 1) {
          newIsCompany = true
        }

        const flatPersonalData = {
              data: {
                _id: this.fetchedOrLiveUser._id,
                email: this.fetchedOrLiveUser.email,
                userID: this.personalData.userID,
                is_company: newIsCompany,
                first_name: this.personalData.first_name,
                last_name: this.personalData.last_name,
                company_name: this.personalData.company_name,
                tax_number: this.personalData.tax_number,
                user_country: this.personalData.user_address.country,
                user_city: this.personalData.user_address.city,
                user_street: this.personalData.user_address.street,
                user_housenumber: this.personalData.user_address.housenumber,
                user_ZIP: this.personalData.user_address.ZIP,
                company_country: this.personalData.company_seat.country,
                company_city: this.personalData.company_seat.city,
                company_street: this.personalData.company_seat.street,
                company_housenumber: this.personalData.company_seat.housenumber,
                company_ZIP: this.personalData.company_seat.ZIP,
              }
        };

        if (this.fetchedOrLiveUser.stripe_id) {
          // eslint-disable-next-line
          flatPersonalData.data['stripe_id'] = this.fetchedOrLiveUser.stripe_id
        }

/*         if (!flatPersonalData.data.is_company) {
          delete flatPersonalData.data.tax_number
        } */

        const keys = Object.keys(flatPersonalData.data);
        console.log('before: ', flatPersonalData);

        keys.forEach((key, index) => {
            if (flatPersonalData.data[key] === '') {
              flatPersonalData.data[key] = null;
            }
        });

        console.log('after: ', flatPersonalData)
        console.log('string: ', JSON.stringify(flatPersonalData))

        /* this.$store.dispatch('extendUser', flatPersonalData) */

        try {
          const response = await fetch("https://bboosterstripe.herokuapp.com/createorupdatecustomer", {
            method: "POST",
            headers: {
              "Content-Type": "application/json"
            },
            body: JSON.stringify(flatPersonalData)
          });
          const res = await response.json()
          console.log("processed: ", res)

          this.validPersonalNotification = false
          this.validTaxNumberNotification = false
          this.invalidTaxNumberNotification = false
          this.errorOccured = false

          if (res.taxNumberResponse !== 'no operation') {
            this.invalidTaxNumberNotification = !res.taxNumberResponse
            this.validTaxNumberNotification = res.taxNumberResponse
          } else {
            this.validPersonalNotification = true
          }
          this.isButtonBusy = false
        } catch (error) {
          console.log('error creating customer: ', error)
          this.errorOccured = true
          this.isButtonBusy = false
        }



      },
    },
    watch: {
      fetchedOrLiveUser(val) {
        console.log('data refreshed on server');
        this.fillData();
      },
    }
  }
</script>

<style lang="scss">
  @import '@/sass/creditcard.scss';
</style>

<style scoped>
.v-card__subtitle, .v-card__text, .v-card__title {
  padding: 0!important;
}
.row {
  margin-right: 0!important;
  margin-left: 0!important;
}
.v-tab {
  height: 70px!important;
}
.invalid-vat-notification {
  position: fixed;
  top: 0;
  width: 80%;
  margin-right: 20px;
  z-index: 100;
}
</style>
<style>
.v-card--material__heading {
  background-color: #e91e63 !important;
}
</style>

<style>
.v-btn__content > .v-progress-circular > svg {
    width: 60%!important;
    height: 60%!important;
}
</style>